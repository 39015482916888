body.welcome-page {
  padding: 0;
  margin: 0;
  min-height: 100%;
  background: #287cb5;

  div.welcome-content {
    color: white;
    width: 75%;
    height: 100%;
    margin: auto;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    padding-bottom: 115px;
    padding-top: 10px;

    a:link {
      color: white;
    }

    a:hover,
    a:visited,
    a:active {
      color: yellow;
    }
  }

  div.welcome-image {
    align-items: center;
    width: 100%;

    img {
      margin: 0 auto;
      display: block;
    }

    .header-img {
      width: 30%;
    }

    .footer-img {
      padding: 10px;
    }
  }

  div.footer {
    position: fixed;
    max-height: 100px;
    bottom: 0;
    width: 100%;
    background: white;
  }
}

@charset "utf-8";

/*BULMA Variables
 ----------------------------------------------------------------------------*/
/************ COLORS ************/
$dark: #46626f;
$light: #c0d6df;
$primary: #007aa8;
$success: #008800;
$warning: #ff920d;
$danger: #e4283a;
$info: #119cd1;
$link: #064a61;
// Using pre-v0.8.0 Bulma value to increase text constrast against message backgrounds.
$info-dark: #0d465c;

$warning-invert: #fff;
$navbar-dropdown-arrow: white !important;

// These show as the link color, by default, but we don't use the link color.
$pagination-current-background-color: $dark;
$pagination-current-border-color: $dark;

/********** NOTIFICATION ***********/
// The default grey color is a bit tough to see on poor quality monitors.
// The color has been darkened slightly to help out with visibility.
$notification-background-color: hsl(0, 0%, 94%);

/********** FONTS ***********/
$family-sans-serif: "Nunito", sans-serif;
$title-color: $dark;
// Bulma uses semi-bold by default, but we don't support semi-bold, we only support weight 400 & 700.
$title-weight: 700;
$subtitle-strong-weight: 700;
$content-heading-weight: 700;

/*********** NAV BAR **********/
$navbar-height: 3.5rem;
$navbar-item-img-max-height: 3rem;

/*********** BOX **********/
// The new default .box shadow makes the border around the box less visible and also has more prominent feathering.
// Reverting shadow properties to what they were prior to upgrade so box border is more bold and feathering is reduced.
$shadow:
  0 2px 3px rgba(black, 0.1),
  0 0 0 1px rgba(black, 0.1);

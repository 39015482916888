div#replacement {
  min-width: 861px;

  textarea#replacement-comment-text {
    width: 100%;
  }
}

div.approved_text {
  color: green;
}

div.denied_text {
  color: #ff0000;
}

td.deleted_daily_quantity {
  color: #ff0000;
}

td.added_daily_quantity {
  color: green;
}

span.fill_width {
  display: block;
  overflow: hidden;
  padding-right: 10px;
}

input.revision_reason_input {
  width: 100%;
}

input.shift_baseline_button {
  width: 8em;
  padding: 0.4em 0.5em;
  word-wrap: break-word;
  white-space: normal;
}

input.small_button {
  width: 5em;
  height: 2.5em;
  padding: 0;
}

img.saved_check_mark {
  display: none;
  vertical-align: middle;
}

table#shift_baselines_table {
  tr {
    height: 3.5em;
  }
  th {
    background-color: #e6e6e8;
  }
  th.left_padding {
    padding-left: 10px;
  }
  tr.even_row_background {
    background-color: #f4f4f6;
  }
}

input#shiftsToRequestLeaveForData {
  display: none;
}

#scrollViewTable {
  .requested-day {
    background-color: $nc-dark-vacation-day;
  }

  .working-day-indicator {
    background-color: $nc-working-day;
  }

  .premium-day-indicator {
    background-color: $nc-premium-day;
  }

  .trade-day-indicator {
    background-color: $nc-trade-day;
  }

  .suggested-leave-day-indicator {
    background-color: $nc-suggested-vacaton;
  }

  .disabled-day {
    background-color: $nc-too-early-for-leave-request;
  }
}

#selected_requests {
  .requested-day {
    background-color: $nc-dark-vacation-day;
    button {
      width: 92px;
    }
  }

  .working-day {
    background-color: $nc-working-day;
    td {
      border-top: 1px solid $nc-dark-working-day;
    }
  }

  .premium-day {
    background-color: $nc-premium-day;
    td {
      border-top: 1px solid darkgoldenrod;
    }
  }

  .unplanned-leave-day,
  .unplanned-day {
    background-color: $nc-unplanned-day;
    td {
      border-top: 1px solid $nc-dark-unplanned-day;
    }
  }

  .planned-leave-day,
  .vacation-day {
    background-color: $nc-vacation-day;
    td {
      border-top: 1px solid $nc-dark-vacation-day;
    }
  }

  .trade-day {
    background-color: $nc-trade-day;
    td {
      border-top: 1px solid $nc-dark-trade-day;
    }
  }
}

.loa_manager_header {
  label {
    font-weight: normal;
  }
  padding-top: 5px;
  padding-bottom: 5px;
}

#request-reason-error-list {
  width: 500px;
}

#leaveRequestContainer {
  #leave_request_reason_question_answer {
    width: 50%;
  }

  #reason_for_request {
    width: 500px;
  }

  td.leave_request_reason_description {
    text-align: right;
    label {
      font-weight: normal;
    }
  }
}

table#employee_vacation {
  td {
    width: 25%;
    text-align: center;
  }

  td.highlighted {
    background-color: $nc-dark-vacation-day;
  }

  tr:last-child {
    td {
      font-weight: normal;
      border-top: 1px solid $black;
      background: transparent;
    }

    // This style is duplicated here to maintain compatibility with non-IE browsers
    td.highlighted {
      background-color: $nc-dark-vacation-day;
    }
  }

  td.value {
    border-right: 1px solid black;
  }
}

th.selected-requests-remove-button-column {
  width: 5%;
}

th.selected-requests-available-for-processing {
  width: 15%;
}

th.selected-requests-title {
  width: 15%;
}

div#update_hints {
  border: 1px solid #aaaaaa;
  padding: 5px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;

  span {
    margin: 5px;
  }
  span.hint-title {
    font-weight: bold;
  }
}

#department-selector-container {
  float: left;
  padding-right: 20px;
  padding-bottom: 10px;
}

#save-button-container button#save_button {
  float: right;
  margin: 0 10px 10px 10px;
}

#update-hints-container {
  float: left;
}

tr.baseline-row td input {
  width: 18px;
}

tr.new-baseline-row {
  background-color: rgba(176, 237, 153, 0.45);
  border-bottom: 1px solid black;

  td input {
    width: 15px;
  }
}

tr.removed-baseline-row {
  background-color: rgba(237, 0, 30, 0.25);
  border-bottom: 1px solid black;

  td input {
    width: 15px;
  }
}

tr.edited-baseline-row {
  background-color: rgba(237, 227, 98, 0.42);
  border-bottom: 1px solid black;

  td input {
    width: 15px;
  }
}

table#baseline-specification-table {
  clear: both;
  border: 1px solid black;
  border-collapse: collapse;

  tr.baseline-row {
    border-bottom: 1px solid black;
  }

  th#occupation-column,
  th#icon-column {
    width: 76px;
  }

  th#time-column {
    width: 100px;
  }

  th.day-column {
    padding-left: 10px;
    width: 50px;
  }

  th#action-column {
    width: 50px;
  }

  td.new-drop-down-cell {
    padding: 2px;
  }

  tr#new.baseline-row {
    background-color: #c9cac9;
  }
}

div.loa-tabs {
  float: left;
  margin-bottom: 10px;
  .ui-tabs-nav {
    padding-left: 17px;
  }
  .ui-widget-content {
    min-height: 10px;
  }
}

div.ui-widget-content {
  min-height: 10px;
}

table.baseline-confirmation-table {
  clear: both;
  border: 1px solid black;
  border-collapse: collapse;

  tr.empty {
    background-color: #eeefee;

    td {
      text-align: center;
      vertical-align: middle;
    }
  }
}

button#save_button {
  float: right;
}

table#pending-baseline-approvals {
  td.status-APPROVED {
    color: green;
  }

  td.status-DENIED {
    color: #ff0000;
  }

  td.status-REVISION_REQUESTED {
    color: orange;
  }
}

table.invisible-table {
  border-style: none;
  border-color: transparent;
  background-color: transparent;

  tbody {
    tr {
      td {
        border-bottom: none;
        background-color: transparent;
      }
    }
  }
}

td.top-align {
  vertical-align: top;
}

td.readonly-quantity {
  min-width: 55px;
  text-align: right;
}

button.full {
  width: 100%;
}

#baseline-quantities {
  position: relative;
  td.quantity-box {
    input[type="text"] {
      min-width: 45px;
      max-width: 70px;
      text-align: right;
    }
  }
  .status-message {
    margin-right: 1rem;
    text-align: right;
    span.spinner-box {
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      img {
        vertical-align: middle;
      }
    }
  }
}

#add-baseline {
  margin-bottom: 2em;
  select {
    min-width: 120px;
  }
  select[name="union"] {
    min-width: 140px;
  }
  select[name="occupation"] {
    min-width: 220px;
  }
  select[name="time_range"] {
    min-width: 180px;
  }
  select[name="shift_class"] {
    max-width: 115px;
  }
  select[name="shift_icon"] {
    max-width: 100px;
  }
}

#guideline-edit {
  #guideline-management {
    button {
      width: 130px;
    }
  }
}

table.view-guidelines {
  tr.revisions-requested {
    td {
      padding-left: 16px;
      background: $nc-light-grey;
      ul {
        list-style-position: inside;
        li {
          list-style-type: circle;
        }
      }
    }
  }
}

table {
  tr.is_active {
    background-color: $nc-light-green-background;
  }
  tr.was_active {
    background-color: #ffeee9;
  }
  tr.will_be_active {
    background-color: lightyellow;
  }
}

hr {
  border: 0;
  border-top: 1px solid lightgray;
}

div.center-wrapped {
  margin: 0 auto;
  display: table;
}

section.note-section {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

#edit-note {
  margin-top: 0.5rem;
}

section,
div.replacement-note-display {
  ol {
    margin: 10px 20px;
    li {
      list-style-type: decimal;
      margin: 5px;
      padding-left: 3px;
    }
  }
  ul {
    margin: 10px 20px;
    li {
      list-style-type: disc;
      margin: 5px;
      padding-left: 3px;
    }
  }
}

table.inline-centered {
  display: inline;
}

.select-attention {
  border: 3px solid orange;
  border-radius: 4px;
  box-shadow: 0 0 2px 2px orange;
}
.select-attention:focus {
  outline: none;
}

td.popup-cell {
  width: 34px;
}

td.popup-cell-yellow {
  background-color: lightyellow;
}

td.popup-cell-green {
  background-color: $nc-light-green-background;
}

section.popup-section {
  &:first-child {
    margin-top: 0;
  }
  margin: 2em 0 1em 0;
}

div.guideline-overlay-container {
  overflow-x: auto;
  width: 60%;
  max-width: 1000px;
  min-height: 400px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

// Display this similar to what our default readonly textarea looks like.
div.guideline-readonly-note-container {
  padding: calc(0.75em - 1px);
  border: solid #dbdbdb 1px;
  border-radius: 4px;
}

// Display this similar to what our default readonly textarea looks like.
div.guideline-readonly-note-container {
  padding: calc(0.75em - 1px);
  border: solid #dbdbdb 1px;
  border-radius: 4px;
}

@use "sass:math";

$super-dark-grey: #333333;
$info-box-dark-grey: #666666;
$light-grey: #cccccc;
$news-box-title-background: #bfddff;
$news-box-content-background: #82bcff;
$news-box-title-text-color: #176cff;

$bottom-area-height: 31em;
$bottom-area-width: 990px;
$bottom-left-area-width: 372px;
$bottom-right-area-width: 600px;
$bottom-area-right-vertical-margin: 1em;
$bottom-area-top-right-height: math.div($bottom-area-height, 2) - $bottom-area-right-vertical-margin;
$bottom-area-bottom-right-height: math.div($bottom-area-height, 2) + 2em;
$cms-area-height: 11em;
$quick-action-button-count: 3;
$quick-action-button-height: math.div($bottom-area-height, $quick-action-button-count);
$quick-action-button-disclosure-height: 2em;
$quick-action-button-disclosure-width: 2em;
$quick-action-button-disclosure-height-margin: math.div(
  $quick-action-button-height - $quick-action-button-disclosure-height,
  2
);
$profile-border-radius: 0.5em;
$profile-border-width: 0.2em;
$profile-border: $profile-border-radius $profile-border-radius 0 0 solid $super-dark-grey;
$bottom-help-top-bar-height: 2em;
$bottom-info-top-bar-height: $bottom-help-top-bar-height;
$box-title-height: 2em;

//Override a paragraph from the base template.
p.content-container {
  margin: 0;
}

div.profile-header {
  margin-top: 1.5em;
  overflow: hidden;
  background-color: $super-dark-grey;
  position: relative; // This helps position elements inside of this element.
}

div.profile-header-subtitle {
  color: white;
  line-height: 1.7em;
  font-size: 1.05em;
  div.cms-page {
    max-height: 90px;
    max-width: 550px !important;
    overflow: hidden;
  }
}

p.bottom-notes-text {
  padding: 1em 0.5em 0 0.5em;
  font-style: italic;
  font-size: 0.95em;
}

div#preload {
  display: none;
}

div.table-cell {
  display: table-cell;
}

img.profile-splash-image {
  min-width: 100%;
  max-width: 100%;
  display: block;
}

.banner-title {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: bolder;
  font-size: 2.5em;
}

.quick-link-mobile {
  @include mobile {
    height: auto;
    white-space: unset;
    text-align: left;
  }
}

.manager-banner-header {
  position: absolute;
  left: 64px;
  top: 75px;
}

.manager-banner-text {
  position: absolute;
  left: 64px;
  top: 140px;
}

.employee-banner-header {
  position: absolute;
  left: 304px;
  top: 75px;
}

.employee-banner-text {
  position: absolute;
  left: 304px;
  top: 140px;
}

.scheduler-banner-header {
  position: absolute;
  left: 375px;
  top: 75px;
}

.scheduler-banner-text {
  position: absolute;
  left: 375px;
  top: 140px;
}

div.theme-northern-profile-header-subtitle div.cms-page {
  max-height: 90px;
  max-width: 475px !important;
  overflow: hidden;
}

.theme-northern-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;

  padding: 10px 20px 35px 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.theme-northern-admin-banner-header {
  @include touch {
    top: 8%;
  }

  @extend .theme-northern-banner-header;
  top: 11%;
  left: 17%;
}

.theme-northern-scheduler-banner-header {
  @include touch {
    top: 8%;
  }

  @extend .theme-northern-banner-header;
  top: 11%;
  left: 10%;
}

.theme-northern-manager-banner-header {
  @include touch {
    top: 8%;
  }

  @extend .theme-northern-banner-header;
  top: 11%;
  left: 17%;
}

.theme-northern-employee-banner-header {
  @include touch {
    top: 8%;
  }

  @extend .theme-northern-banner-header;
  top: 11%;
  left: 28%;
}

.theme-northern-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
  margin-bottom: 0;
}

.theme-northern-banner-subtitle {
  color: white;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-northern-employee-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-northern-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-northern-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: black;
}

/* Vancouver Coastal */

.theme-vancouver_coastal-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;

  padding: 10px 20px 35px 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.theme-vancouver_coastal-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-vancouver_coastal-banner-header;
  top: 14%;
  left: 28%;
}

.theme-vancouver_coastal-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-vancouver_coastal-banner-header;
  top: 14%;
  left: 28%;
}

.theme-vancouver_coastal-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-vancouver_coastal-banner-header;
  top: 14%;
  left: 28%;
}

.theme-vancouver_coastal-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-vancouver_coastal-banner-header;
  top: 14%;
  left: 28%;
}

.theme-vancouver_coastal-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-vancouver_coastal-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-vancouver_coastal-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-vancouver_coastal-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: black;
}

/* Island Health */

.theme-island_health-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;

  padding: 10px 20px 35px 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.theme-island_health-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-island_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-island_health-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-island_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-island_health-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-island_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-island_health-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-island_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-island_health-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-island_health-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-island_health-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-island_health-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: black;
}

/* Andgo Branding theme */

.theme-andgo-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;

  padding: 10px 20px 35px 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.theme-andgo-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-andgo-banner-header;
  top: 14%;
  left: 28%;
}

.theme-andgo-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-andgo-banner-header;
  top: 14%;
  left: 28%;
}

.theme-andgo-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-andgo-banner-header;
  top: 14%;
  left: 28%;
}

.theme-andgo-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-andgo-banner-header;
  top: 14%;
  left: 28%;
}

.theme-andgo-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-andgo-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-andgo-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-andgo-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: black;
}

/* Providence Vancouver */

.theme-providence_vancouver-splash-image {
  background-color: white;
}

.theme-providence_vancouver-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;

  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-providence_vancouver-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-providence_vancouver-banner-header;
  top: 14%;
  left: 28%;
}

.theme-providence_vancouver-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-providence_vancouver-banner-header;
  top: 14%;
  left: 28%;
}

.theme-providence_vancouver-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-providence_vancouver-banner-header;
  top: 14%;
  left: 28%;
}

.theme-providence_vancouver-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-providence_vancouver-banner-header;
  top: 14%;
  left: 28%;
}

.theme-providence_vancouver-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-providence_vancouver-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-providence_vancouver-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-providence_vancouver-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: black;
}

/* Goverment of Saskatchewan */

.theme-government_of_saskatchewan-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;

  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-government_of_saskatchewan-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-government_of_saskatchewan-banner-header;
  top: 14%;
  left: 28%;
}

.theme-government_of_saskatchewan-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-government_of_saskatchewan-banner-header;
  top: 14%;
  left: 28%;
}

.theme-government_of_saskatchewan-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-government_of_saskatchewan-banner-header;
  top: 14%;
  left: 28%;
}

.theme-government_of_saskatchewan-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-government_of_saskatchewan-banner-header;
  top: 14%;
  left: 28%;
}

.theme-government_of_saskatchewan-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-government_of_saskatchewan-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-government_of_saskatchewan-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-government_of_saskatchewan-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: black;
}

.theme-government_of_saskatchewan-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;

  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

/* Keurig Dr.Pepper */

.theme-keurig_drpepper-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-keurig_drpepper-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-keurig_drpepper-banner-header;
  top: 14%;
  left: 28%;
}

.theme-keurig_drpepper-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-keurig_drpepper-banner-header;
  top: 14%;
  left: 28%;
}

.theme-keurig_drpepper-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-keurig_drpepper-banner-header;
  top: 14%;
  left: 28%;
}

.theme-keurig_drpepper-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-keurig_drpepper-banner-header;
  top: 14%;
  left: 28%;
}

.theme-keurig_drpepper-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-keurig_drpepper-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-keurig_drpepper-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* Thunder Bay Health */

.theme-thunder_bay_health-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-thunder_bay_health-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-thunder_bay_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-thunder_bay_health-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-thunder_bay_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-thunder_bay_health-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-thunder_bay_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-thunder_bay_health-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-thunder_bay_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-thunder_bay_health-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-thunder_bay_health-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-thunder_bay_health-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* Schlegel Villages */

.theme-schlegel_villages-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-schlegel_villages-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-schlegel_villages-banner-header;
  top: 14%;
  left: 28%;
}

.theme-schlegel_villages-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-schlegel_villages-banner-header;
  top: 14%;
  left: 28%;
}

.theme-schlegel_villages-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-schlegel_villages-banner-header;
  top: 14%;
  left: 28%;
}

.theme-schlegel_villages-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-schlegel_villages-banner-header;
  top: 14%;
  left: 28%;
}

.theme-schlegel_villages-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-schlegel_villages-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-schlegel_villages-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-schlegel_villages-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* Cleveland Clinic London */

.theme-cleveland_clinic_london-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-cleveland_clinic_london-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-cleveland_clinic_london-banner-header;
  top: 14%;
  left: 28%;
}

.theme-cleveland_clinic_london-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-cleveland_clinic_london-banner-header;
  top: 14%;
  left: 28%;
}

.theme-cleveland_clinic_london-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-cleveland_clinic_london-banner-header;
  top: 14%;
  left: 28%;
}

.theme-cleveland_clinic_london-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-cleveland_clinic_london-banner-header;
  top: 14%;
  left: 28%;
}

.theme-cleveland_clinic_london-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-cleveland_clinic_london-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-cleveland_clinic_london-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-cleveland_clinic_london-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* National Beef */

.theme-national_beef-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-national_beef-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-national_beef-banner-header;
  top: 14%;
  left: 28%;
}

.theme-national_beef-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-national_beef-banner-header;
  top: 14%;
  left: 28%;
}

.theme-national_beef-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-national_beef-banner-header;
  top: 14%;
  left: 28%;
}

.theme-national_beef-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-national_beef-banner-header;
  top: 14%;
  left: 28%;
}

.theme-national_beef-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-national_beef-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-national_beef-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-national_beef-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* Island Health */

.theme-johns_hopkins-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-johns_hopkins-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-johns_hopkins-banner-header;
  top: 14%;
  left: 28%;
}

.theme-johns_hopkins-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-johns_hopkins-banner-header;
  top: 14%;
  left: 28%;
}

.theme-johns_hopkins-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-johns_hopkins-banner-header;
  top: 14%;
  left: 28%;
}

.theme-johns_hopkins-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-johns_hopkins-banner-header;
  top: 14%;
  left: 28%;
}

.theme-johns_hopkins-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-johns_hopkins-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-johns_hopkins-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-johns_hopkins-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* Ontario Shores */

.theme-ontario_shores-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-ontario_shores-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-ontario_shores-banner-header;
  top: 14%;
  left: 28%;
}

.theme-ontario_shores-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-ontario_shores-banner-header;
  top: 14%;
  left: 28%;
}

.theme-ontario_shores-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-ontario_shores-banner-header;
  top: 14%;
  left: 28%;
}

.theme-ontario_shores-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-ontario_shores-banner-header;
  top: 14%;
  left: 28%;
}

.theme-ontario_shores-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-ontario_shores-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-ontario_shores-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-ontario_shores-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* fresno_community */

.theme-fresno_community-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-fresno_community-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-fresno_community-banner-header;
  top: 14%;
  left: 28%;
}

.theme-fresno_community-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-fresno_community-banner-header;
  top: 14%;
  left: 28%;
}

.theme-fresno_community-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-fresno_community-banner-header;
  top: 14%;
  left: 28%;
}

.theme-fresno_community-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-fresno_community-banner-header;
  top: 14%;
  left: 28%;
}

.theme-fresno_community-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-fresno_community-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-fresno_community-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-fresno_community-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* sha esp */

.theme-sha_esp-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-sha_esp-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-sha_esp-banner-header;
  top: 14%;
  left: 28%;
}

.theme-sha_esp-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-sha_esp-banner-header;
  top: 14%;
  left: 28%;
}

.theme-sha_esp-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-sha_esp-banner-header;
  top: 14%;
  left: 28%;
}

.theme-sha_esp-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-sha_esp-banner-header;
  top: 14%;
  left: 28%;
}

.theme-sha_esp-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-sha_esp-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-sha_esp-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-sha_esp-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* rmh wfd */

.theme-rmh_wfd-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-rmh_wfd-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-rmh_wfd-banner-header;
  top: 14%;
  left: 28%;
}

.theme-rmh_wfd-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-rmh_wfd-banner-header;
  top: 14%;
  left: 28%;
}

.theme-rmh_wfd-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-rmh_wfd-banner-header;
  top: 14%;
  left: 28%;
}

.theme-rmh_wfd-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-rmh_wfd-banner-header;
  top: 14%;
  left: 28%;
}

.theme-rmh_wfd-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-rmh_wfd-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-rmh_wfd-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-rmh_wfd-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* tgh - tampa_general */

.theme-tampa_general-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-tampa_general-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-tampa_general-banner-header;
  top: 14%;
  left: 28%;
}

.theme-tampa_general-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-tampa_general-banner-header;
  top: 14%;
  left: 28%;
}

.theme-tampa_general-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-tampa_general-banner-header;
  top: 14%;
  left: 28%;
}

.theme-tampa_general-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-tampa_general-banner-header;
  top: 14%;
  left: 28%;
}

.theme-tampa_general-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-tampa_general-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-tampa_general-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-tampa_general-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* fairview_health */

.theme-fairview_health-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-fairview_health-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-fairview_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-fairview_health-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-fairview_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-fairview_health-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-fairview_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-fairview_health-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-fairview_health-banner-header;
  top: 14%;
  left: 28%;
}

.theme-fairview_health-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-fairview_health-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-fairview_health-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-fairview_health-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* blue_diamond */

.theme-blue_diamond-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-blue_diamond-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-blue_diamond-banner-header;
  top: 14%;
  left: 28%;
}

.theme-blue_diamond-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-blue_diamond-banner-header;
  top: 14%;
  left: 28%;
}

.theme-blue_diamond-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-blue_diamond-banner-header;
  top: 14%;
  left: 28%;
}

.theme-blue_diamond-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-blue_diamond-banner-header;
  top: 14%;
  left: 28%;
}

.theme-blue_diamond-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-blue_diamond-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-blue_diamond-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-blue_diamond-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* trillium_health_partners */

.theme-trillium_health_partners-banner-header {
  font-family: Verdana, Geneva, sans-serif;
  position: absolute;
  padding: 10px 20px 35px 20px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
}

.theme-trillium_health_partners-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-trillium_health_partners-banner-header;
  top: 14%;
  left: 28%;
}

.theme-trillium_health_partners-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-trillium_health_partners-banner-header;
  top: 14%;
  left: 28%;
}

.theme-trillium_health_partners-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-trillium_health_partners-banner-header;
  top: 14%;
  left: 28%;
}

.theme-trillium_health_partners-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-trillium_health_partners-banner-header;
  top: 14%;
  left: 28%;
}

.theme-trillium_health_partners-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-trillium_health_partners-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

.theme-trillium_health_partners-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

.theme-trillium_health_partners-employee-banner-text {
  position: relative;
  text-align: right;
  margin-top: -20px;
  color: white;
}

/* Andgo CDN Branding theme */
/* This will become default Andgo Branding theme once all customers have moved to CDN Branding */

.theme-andgo-cdn-banner-header {
  position: absolute;
  padding: 10px 20px 10px 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.theme-andgo-cdn-admin-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-andgo-cdn-banner-header;
  top: 14%;
  left: 28%;
}

.theme-andgo-cdn-scheduler-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-andgo-cdn-banner-header;
  top: 14%;
  left: 28%;
}

.theme-andgo-cdn-manager-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-andgo-cdn-banner-header;
  top: 14%;
  left: 28%;
}

.theme-andgo-cdn-employee-banner-header {
  @include touch {
    top: 10%;
  }

  @extend .theme-andgo-cdn-banner-header;
  top: 14%;
  left: 28%;
}

.theme-andgo-cdn-banner-title {
  color: $primary;
  font-weight: 500;
  font-size: 3.2vw;
  text-align: left;
  margin-top: -30px;
  padding: 0;
}

.theme-andgo-cdn-banner-subtitle {
  color: $primary;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: left;
  margin-top: -10px;
}

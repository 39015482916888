#administration-demo {
  input[type="text"] {
    width: 167px;
  }
  select {
    width: 92px;
  }
}

span.inline-form {
  p {
    display: inline;
  }
}

div.header {
  padding-top: 6px;
  padding-bottom: 4px;
  font-weight: bold;
}
div.contents {
  padding-left: 12px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

td.no-padding {
  padding: 0;
}
td.const-width-buttons {
  width: 70px;
  max-width: 70px;
  min-width: 70px;
  form button {
    width: 100%;
  }
  a.button {
    width: 100%;
  }
}
td.active-display {
  width: 43px;
  text-align: center;
}
td.active-time-range {
  width: 153px;
}
table.centered-cells {
  td {
    text-align: center;
  }
}

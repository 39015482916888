@mixin wfd-tile() {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background: $primary-alt;
  border-radius: 5px;
}
.wfd-tile-body {
  padding: 5px;
}
.wfd-tile {
  @include wfd-tile();
}
.small-wfd-tile {
  @include wfd-tile();
  max-height: 90px;
}
.medium-wfd-tile {
  @include wfd-tile();
  max-height: 240px;
}
.large-wfd-tile {
  @include wfd-tile();
  max-height: 430px;
}
.wfd-columns {
  color: #1f1d1d;
  background: white;
  margin-left: -1rem;
  margin-right: -1rem;

  .left-padded-column {
    padding-left: 15px !important;
  }

  .column {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  }

  .wfd-column-header {
    border-bottom: 1px solid #dbdbdb;
    background: $primary-alt;
    padding: 0 0.5rem 5px 0.5rem;
    color: white;
    flex: auto !important;
  }
}
.full-width {
  width: 100%;
}
.no-profiles-dropdown {
  background: white;
  color: #1f1d1d;
  padding-left: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px auto;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}
.dropdown {
  margin-bottom: 5px;
}

div.landing-page-dashboard {
  width: 100%;
  overflow-x: auto;

  table {
    thead {
      tr {
        th:nth-child(1) {
          width: 170px;
        }
        th {
          font-size: 8pt;
        }
      }
    }
  }

  .dashboard-title {
    font-size: 1.6em;
    font-weight: 500;
    vertical-align: baseline;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  }

  div.cms-page {
    width: 100% !important;
  }
}

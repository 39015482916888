@import "bulma_overrides";
@import "~bulma/bulma";

$primary-alt: #074e66;
$highlight: #acfcd9;
$soft-stroke: #ebedef;

$nc-light-grey: #f4f4f6;
$nc-dark-grey: #bac7d4;
$nc-very-dark-grey: #50505f;

$nc-unfilled-shift: #f6c2b5;
$nc-baseline-negative: #ff9e94;
$nc-baseline-positive: #ff9e94;

$nc-light-green-background: #caffc2; // also used in LOA
$nc-working-day: $nc-light-green-background;
$nc-premium-day: #ffe38b;
$nc-unplanned-day: #ffc0cb;
$nc-vacation-day: #c9daff;
$nc-suggested-vacaton: #f9e5c4;
$nc-available-shift-day: MistyRose;
$nc-too-early-for-leave-request: #dbddde;
$nc-trade-day: #d5c8f8;

$nc-dark-vacation-day: #86abd8;
$nc-dark-working-day: #a0cc8a;
$nc-dark-unplanned-day: darkred;
$nc-dark-trade-day: #6c31a0;
$nc-footer-height: 169px;

$nc-calendar-event-blue: #3498db;

@import "ui";
@import "scheduling";
@import "administration";
@import "loa_guideline";
@import "account";
@import "homepages";
@import "homepage_dashboards";
@import "tabs";
@import "welcome";
@import "landing";
@import "wfd_tiles";
@import "org_node_picker";

pre[class*="language-"].pre-code {
  width: 100%;
  max-width: 100%;
  overflow-x: auto !important;
  padding-right: 2.5em; // extra right padding so our copy button doesn't overlap anything.
}

/* This styling matches the default prism.js styling for the 'number' and 'tag' class. We use a prism plugin to change
 * those default class names from 'number' and 'tag' to 'prism-number' and 'prism-tag' because 'number' and 'tag'
 * conflict with bulma class names.
 */
.prism-number,
.prism-tag {
  all: inherit;
  color: #905;
}

button.copy-code-example {
  position: absolute;
  right: 0.5em;
  top: 1em;
}

.has-border {
  border-width: 1px;
  border-style: solid;
}

.has-border-success {
  border-color: $success;
}

.has-border-warning {
  border-color: $warning;
}

.has-border-danger {
  border-color: $danger;
}

.scroll-behaviour-smooth {
  scroll-behavior: smooth;
}

.ReactCollapse--content--rounded {
  border-radius: 5px;
  overflow: hidden;
}

#no-webpack-dev-server-warning {
  display: none;
}

html {
  height: 100%;
  margin: 0;
  overflow-x: auto;
}

.rich_text {
  font-size: 12px;
}

.message {
  .message-body {
    // Given our layout colors the message body background colors don't stand out enough against the webpage
    // background color. As such, give the body a solid border to help differentiate it.
    border-width: 1px 1px 1px 4px;
    border-style: solid;
  }
  .message-header + .message-body {
    // If a message header exists, we want a 1px border all around, without a wider border on the left.
    border-left-width: 1px;
  }
}

.checkbox-label {
  // Vertically centering text and checkboxes together is hard. https://stackoverflow.com/a/306593/932896
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}

.reset-font-weight {
  // In some cases if a title tag has font-weight bold set, but you want to only bold parts of the title
  // making use of inner <strong> tags, this class can be useful.
  font-weight: unset;
  strong {
    font-weight: bold;
  }
}

.checkbox-input {
  // Vertically centering text and checkboxes together is hard. https://stackoverflow.com/a/306593/932896
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
  *overflow: hidden;
}

.navbar {
  // We probably want to box our whole website, until then, here's a tiny bit of padding.
  padding: 0 0.5rem;

  @include desktop {
    // This is the breakpoint for switching to a mobile/hamburger nav bar.
    .navbar-link {
      color: white !important;
    }
  }
}

form.vacation-group-manager-processing {
  textarea {
    width: 180px;
    height: 50px;
  }
}

tr.no-border-bottom > td {
  border-bottom: none;
}

tr.striped {
  td,
  th {
    background-color: $table-striped-row-even-background-color;
  }
}

.Month-week {
  flex: 1;
}

.Month-day {
  max-width: 14.28%;
}

.float_left {
  float: left;
}

.max-width-100 {
  max-width: 100%;
}

.float_right {
  float: right;
}

div.inline,
form.inline {
  display: inline-block;
}

td.restrict-error-width ul.errorlist {
  max-width: 440px;
}

.pad_bottom {
  padding-bottom: 10px;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.datepickercontainer {
  display: flex;
  width: 100%;
}

.tag.is-premium {
  color: black;
  background-color: #ff920d;
}

.tag.is-working {
  color: white;
  background-color: #21619f;
}

.tag.is-not-working {
  color: white;
  background-color: #595959;
}

.tag.is-ignore {
  color: white;
  background-color: black;
}

.tag.is-dark-grey {
  background-color: #7c7c7c;
}

span.restrict-title-width {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: -9px;
}

#header {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;

  h1 {
    img {
      margin-top: 5px;
      vertical-align: bottom;
      float: left;
    }
  }

  #header_text {
    font-size: 1em;
    margin-top: 4px;
    padding-left: 16px;
    float: left;
    color: #7a9ab9;
    line-height: 52px;
    font-weight: bold;
    font-style: italic;
  }

  .level {
    margin-bottom: 0;
  }
}

.chevron-animate {
  -webkit-transition: transform 0.25s ease-in; /* Safari */
  transition: transform 0.25s ease-in;
}

#banner {
  position: relative;
  height: 28px;
  border-radius: 4px 4px 0 0;
  border: solid black 1px;
  border-bottom-width: 0;

  background-image: url(/static/images/top-bar-bg4.gif);
  behavior: url(/static/vendor/css3pie/PIE.htc);
}

span.red {
  color: red;
}

td.signature {
  border-bottom: 1px solid $black;
  width: 300px;
}

ul.horiz > li {
  display: inline;
}

div.horizontal-button-group {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  float: none;
  width: 90%;

  ul {
    text-align: center;
  }

  ul > li {
    display: inline;
  }
}

div.underlined {
  border-bottom: 1px solid #dbdbdb;
}

li.even {
  background-color: #e5ecf9;
}

.width_auto {
  width: auto;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}

div.employee {
  li {
    margin-left: 0;
    padding-left: 0;
  }

  ul.other-departments {
    margin-left: 18px;

    li {
      list-style-type: disc;
    }
  }
}

.grey-border {
  border: 2px solid $nc-dark-grey;
  padding: 10px 20px;
}

.replacement-note-submit-container {
  float: right;
}

a.collapsible-button {
  float: right;
}

.ui-button {
  font-size: 7pt;
}

table.user-profile-table {
  // Min width is required for the widths to be respected on firefox

  // Column that displays a date/time
  .time-row {
    width: 200px;
    min-width: 200px;
  }

  // Column that displays a checkmark or X icon
  .is-active-row {
    width: 70px;
    min-width: 70px;
  }

  // Column with a small button
  .button-row {
    width: 100px;
    min-width: 100px;
  }
}

ul.unordered-list {
  list-style-type: square;

  li {
    list-style-type: circle;
    margin-left: 16px;
  }
}

ul.vertical-list {
  li {
    display: inline-block;
  }
}

.error,
.info,
.success,
.warning {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}

.error ul {
  margin-left: 17px;
}

.errorCell {
  border: 1px solid #fbc2c4 !important;
  background-color: #fbe3e4 !important;
  color: #8a1f11 !important;

  padding: 6px 5px 5px 6px;
  border-radius: 0;
}

.warning {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  padding: 0.8em;
  background: rgb(245, 245, 245);
  border: 2px solid rgb(221, 221, 221);
}

.greyed-out {
  color: $nc-dark-grey;
}

.dashboard-cell {
  background-color: white;
}

.dashboard-icon-cell {
  width: 16px;
  padding-right: 0;
}

.dashboard-table td {
  border-bottom: 1px solid $grey;
}

.dashboard-department-info {
  border-right: 1px solid $grey;
}

.dashboard-department-info,
.dashboard-border td {
  border-bottom: 1px solid $nc-dark-grey !important;
}

div.planned-leave-day,
.submission-under {
  border: 1px solid black;
  background-color: $nc-vacation-day;
  color: black;
}

.submission-ok,
span.resolution-GRANTED {
  border: 1px solid black;
  background-color: $nc-working-day;
  color: black;
}

div.submission-empty,
span.submission-empty {
  border: 1px solid black;
  background-color: $nc-premium-day;
  color: black;
}

div.unplanned-leave-day,
.submission-over,
span.resolution-DENIED {
  border: 1px solid black;
  background-color: $nc-unplanned-day;
  color: black;
}

div.disabled-day {
  border: 1px solid black;
  background-color: $nc-too-early-for-leave-request;
  color: black;
}

.smallText {
  font-size: 10px;
}

table.is-inner-table {
  // Nested tables need the border between the head and the body to be added manually because this styling disappears
  // when a table is nested.
  thead tr {
    border-bottom: 2px solid $border;
  }

  // Nested tables need the border between the rows to be added manually because:
  // 1) If this nested table is within the last row of the parent table, bulma hides the borders of the last row which
  // also hides all borders in this nested table.
  // 2) The border styling disappears when a table is doubly nested
  tbody tr {
    border-bottom: $table-cell-border;
  }

  tbody tr:last-child {
    border-bottom: none;
  }
}

.is-inner-table-container {
  // If you want an inner table to be indented, the margin has to be added to a parent div instead of the table,
  // otherwise it pushes the content outside the normal bounds of the parent table.
  margin: 0 0 2em 2em;
}

tr.empty {
  td {
    text-align: center;
    font-weight: bold;
  }
}

tr.centered-row td {
  text-align: center;
}

ul.help-items {
  li {
    margin-left: 18px;
    margin-bottom: 2px;
  }

  .top-gap {
    margin-top: 20px;
  }
}

.additional-prop {
  font-weight: bold;
  color: #ee4e48;
}

.department-links {
  display: flex;
  flex-direction: column;
  a {
    margin-bottom: 3px;
  }
}

#users-notifications {
  label {
    font-weight: normal;
  }

  th.sub-heading {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $nc-light-grey;
  }

  td.three-options,
  td.four-options {
    text-align: center;
  }

  ul.check-box-list {
    padding-left: 20px;
    padding-right: 20px;

    li {
      width: 120px;
      text-align: left;
      display: inline-block;

      label {
      }
    }

    li.wide {
      width: 150px;
    }
  }

  td.select-all-buttons {
    text-align: left;
  }
}

.bottom-border {
  border-bottom: 1px solid #dbdbdb;
}

.box {
  @include mobile {
    padding: 1rem;
  }
}

td pre {
  white-space: normal;
}

.border-box {
  margin: 4px;
  border: 1px solid $nc-dark-grey;
  padding: 8px;
  min-height: 50px;
}

.audiojs {
  width: 200px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;

  .progress,
  .loaded {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
  }

  .scrubber {
    width: 140px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
  }

  .time {
    display: none;
  }
}

.hidden {
  display: none;
}

table#recordings {
  tr.failed {
    background-color: #ffeee9;
  }

  tr.pending {
    background-color: lightyellow;
  }
}

.table-vertical-margin {
  margin-top: 1.4em;
  margin-bottom: 1.4em;
}

.username-container {
  width: 90%;
  div,
  span,
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.cms-page {
  width: 100%;
}

.control-panel {
  padding: 4px 8px;

  input,
  button,
  a {
    margin: 2px 4px 0;
  }
}

.control-panel.inline,
.control-panel.inline.inline > * {
  display: inline-block;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

.body-content-wrapper {
  min-height: 100%;
  .body-content-wrapper-inner {
    // When we don't need to support IE11 we can use flex-box for the sticky footer.
    // Flexbox sticky footer implementations don't work in IE11 because of an IE11 flexbox bug.
    padding-bottom: $nc-footer-height;
  }
}

body {
  font-family: "Nunito", sans-serif;
  background-color: #f5f7fa;
  color: #46626f;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  ul.notes li {
    padding: 10px 10px 5px;

    ul li {
      padding: 0;
    }

    p {
      margin: 0.5em 0;
    }
  }

  div.half {
    width: 48%;
  }

  td {
    padding: 6px;
  }

  #messages {
    li {
      margin-bottom: 12px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    margin-left: auto;
    margin-right: auto;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  #heading {
    width: 100%;
    margin-bottom: 2px;
  }

  #breadcrumbs {
    margin-bottom: 2px;
  }

  #container {
    margin: 0 auto;
    min-height: 85vh;
  }

  #content {
    display: flex;
    height: 100%;
    justify-content: center;

    div.inner {
      flex: 1;
      max-width: 100%;
      padding-right: 0.75em;
      padding-left: 0.75em;
      padding-bottom: 0.75em;

      @include tablet {
        padding-right: 2em;
        padding-left: 2em;
        padding-bottom: 2em;
      }

      h1 {
        padding-top: 0.75rem;
        @include tablet {
          padding-top: 2rem;
        }
      }
    }

    div.full-screen-mobile-inner {
      @extend div, .inner;
      @include mobile {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  #middle {
    position: relative;
    width: 100%;
    flex: 1;
  }

  #status,
  #ajax-active-spinner {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 8px;
    font-weight: bold;
    text-align: right;
  }

  #ajax-active-spinner {
    margin-top: 2rem;
    margin-right: 2rem;
  }

  #status-message {
    position: absolute;
    top: 5px;
    right: 0;
    margin-right: 17px;
    margin-bottom: 5px;
    font-size: 12px;

    span {
      text-align: right;
      vertical-align: middle;
      height: 42px;
    }
  }

  #status a {
    padding-left: 8px;
  }

  div#ui-datepicker-div.ui-datepicker {
    min-height: 123px;

    table tr th {
      background: none;
    }
  }
}

/*Random Style*/
.col-left {
  min-width: 50%;
  display: inline-block;
}

.col-right {
  min-width: 50%;
  text-align: right;
  display: inline-block;
}

a {
  text-decoration: none;

  &:visited {
    text-decoration: none;
  }
}

.box-header {
  display: flex;
  flex-wrap: wrap;
}

.box-bottom-margin-fix {
  margin-bottom: 1.5rem;
}

/* Buttons
---------------------------------------------------------------------------- */
.button {
  @include transition(background-color 0.3s ease, color, 0.3s ease);
  // Override/duplicate enough of Bulma's button styles to override its default hover-state styling.
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      &:hover,
      &.is-hovered {
        background-color: darken($color, 7.5%);
        border-color: transparent;
        color: $color-invert;
      }
    }
  }
}

/* Tabular Data
---------------------------------------------------------------------------- */

#schedule-table {
  td {
    font-size: 0.85em;
  }

  .tag {
    width: 100%;
  }

  .is-trade {
    background-color: #7a53a2;
    color: #fff;
  }
}

.dashboard-container {
  // overflow-x disables position:sticky without a set height so we only want overflow-x when we know there will be
  // overflow.
  @include until-widescreen {
    overflow-x: scroll;
  }
}

.dashboard-sticky-container {
  border-top: solid white 1.25rem; // hide scrolling at top and bottom with border
  border-bottom: solid white 1.25rem;
  padding-top: 0; // zero out padding to make use of border
  padding-bottom: 0;

  @include touch {
    overflow-x: scroll;
    border-top: solid white 1rem;
    border-bottom: solid white 1rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}

$top-header-height: 3.5rem;

.table.status-table {
  width: 100%;
  border-collapse: separate;

  .dashboard-left-cell {
    text-align: right;
    font-size: 1.1em;
    border-width: 0 2px 2px 0;
  }

  .dashboard-team-cell {
    text-align: right;
    font-size: 1.1rem;
    border-style: solid;
    border-color: #dbdbdb;
    border-width: 0 2px 2px 0;
  }

  tr.primary-header {
    th {
      vertical-align: middle;
      box-sizing: border-box;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      height: $top-header-height;
      line-height: 1;
    }
    &.bordered {
      th {
        border-right: 2px solid #dbdbdb;
        border-bottom: 2px solid #dbdbdb;
      }
    }
    &.bottom-bordered {
      th {
        border-bottom: 2px solid #dbdbdb;
      }
    }
  }

  tr.secondary-header {
    font-size: 12px;
    th {
      vertical-align: middle;
      position: sticky;
      // The second row of table headers are offset by the height of the first row of table headers.
      top: $top-header-height;

      &.last {
        border-right: 2px solid #dbdbdb;
      }
    }
  }

  tr.secondary-header.bordered {
    th {
      border-right: 2px solid #dbdbdb;
      border-bottom: 2px solid #dbdbdb;
    }
  }

  th {
    background-color: white;
    text-align: center;
    position: sticky;
    top: 0;
  }

  td.status {
    text-align: center;
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-color: #dbdbdb;
    vertical-align: middle;
    font-size: 1.2rem !important;
    padding: 0.4em 0.5em;

    a {
      display: block;
    }
  }

  td.is-green {
    background-color: #caffc2;

    &:hover {
      background-color: #addda5;
    }

    a {
      color: black;
    }
  }

  td.is-red {
    background-color: #ff745b;

    &:hover {
      background-color: #da6851;
    }

    a {
      color: black;
    }
  }

  // The colours chosen here are temporary. We should get a proper design for these.
  td.is-yellow {
    background-color: #ffe899;

    &:hover {
      background-color: #e0c97e;
    }

    a {
      color: black;
    }
  }

  td.is-light-blue {
    background-color: #d0edfd;

    &:hover {
      background-color: #b6d3e3;
    }

    a {
      color: black;
    }
  }

  td.is-light-red {
    background-color: #eec4b7;

    &:hover {
      background-color: #d2a89b;
    }

    a {
      color: black;
    }
  }

  td.is-grey {
    background-color: #e6e6e8;

    &:hover {
      background-color: #cacacc;
    }

    a {
      color: black;
    }
  }
}

.block-shift-table {
  thead tr:first-child {
    border-bottom-style: solid;
    border-bottom-color: #dbdbdb;
    border-width: 0 0 2px;
  }
}

.table.vertically-centered-cells > tbody > tr > td {
  vertical-align: middle;
}

.table.vertically-centered-headings > thead > tr > th {
  vertical-align: middle;
}

/* Pagination
---------------------------------------------------------------------------- */

.pagination {
  display: flex;
  list-style: none;
  border-radius: 8px;
  padding: 0.75rem 0.35rem 0.5rem 0.35rem;

  .page-item {
    display: list-item;

    .page-link {
      position: relative;
      display: block;
      padding: 10px 16px;
      margin-left: -1px;
      line-height: 1.25;
      color: $primary;
      background-color: transparent;
      border: 1px solid $soft-stroke;
      @include transition(background-color 0.3s ease, color 0.3s ease);

      &:hover {
        background-color: $primary;
        color: white;
      }
    }

    &.active {
      .page-link {
        background-color: $primary;
        color: white;
      }
    }

    &:nth-child(1) {
      .page-link {
        border-radius: 8px 0 0 8px;
      }
    }

    &:last-child {
      .page-link {
        border-radius: 0 8px 8px 0;
      }
    }
  }
}

/* Tabs
---------------------------------------------------------------------------- */

.tabs {
  margin-bottom: 0 !important;

  div {
    width: 100%;
  }
}

// This can be used to clearly indicate required fields on search forms. Give the form the 'required_css_class' of
// 'starred_required_field' and an asterisk will be appended to all required field labels.
label.starred_required_field:after {
  content: " *";
}
div.starred_required_field:after {
  content: "* required";
}

.table-box {
  border-left: solid 1px #dbdbdb;
  border-right: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
  background: white;
}

.table-box-content {
  // Matching the bulma "box" class.
  padding: 1.25rem;
}

/* margin for status update messages (error text, success messages, information updates) */
.status-message-margin {
  margin: 8px 5px 5px 5px;

  strong {
    color: white;
  }
}

// TODO find a better way to style errors, or override errors displayed by crispy form errors.
.alert {
  text-align: center !important;
  @extend .status-message-margin, .notification, .is-danger;
}

.danger-outlined-field {
  input,
  textarea,
  trix-editor {
    border-color: $danger;
  }
}

/* Widths for form fields */

.form-field-width {
  width: 100%;
}

.select.form-field-width {
  select {
    width: 100%;
  }
}

.form-top-margin {
  margin-top: 12px;
}

.submit-button-top-margin {
  margin-top: 8px;
}

ul.supported-browsers {
  li {
    list-style-type: disc;
    list-style-position: inside;
  }
}

.unprocessed_none {
  @extend .is-green;
  @extend .status;
}
.unprocessed_ok {
  @extend .is-yellow;
  @extend .status;
}
.unprocessed_late {
  @extend .is-red;
  @extend .status;
}

.only-display-in-iframe {
  display: none; // We hide this by default. JS decides if we should display these elements in `onlyDisplayInIframe`.
}

.force-column-striped {
  background-color: $table-striped-row-even-background-color;
}

.force-column-no-border td {
  border-width: 0 !important;
}

#after-time-window-notification {
  vertical-align: middle;
  text-align: center;
  border-width: 0;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px dashed #dbdbdb;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.ReactCollapse--collapse {
  transition: height 350ms;
  overflow: visible; // for ie 11
}

.date-picker-input-close {
  border-radius: 50%;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}
.date-picker-input-close:hover {
  @extend .has-background-grey-lighter;
}

.quota-title {
  @extend .title;
  margin-bottom: 0 !important;
}

.quota-grid {
  display: grid;
  padding: 1em 0 1em 0;
}

.weekly-quota-grid {
  @extend .quota-grid;
  grid-template-columns: 250px repeat(7, 100px) 1fr;
}

.weekly-quota-grid-advanced {
  @extend .quota-grid;
  grid-template-columns: 250px repeat(8, 100px) 1fr;
}

.weekly-quota-bordered {
  border-top: 1px solid #e7e7e7;
}

.weekly-quota-sidebar {
  padding-top: 0.5em;
  grid-row-start: 2;
  grid-row-end: 6;
}

.quota-table-header-item {
  display: flex;
  flex-direction: column;
  color: $primary-alt;
  margin-bottom: 0.5em;
}
.quota-table-header-item-first {
  border-bottom: 1px solid #e7e7e7;
}

.quota-table-row {
  padding: 0.5em;
  width: 100px;
}

.weekly-quota-week-quota {
  grid-row-start: 2;
  grid-row-end: 6;
  grid-column-start: 9;
}
.weekly-quota-week-quota-advanced {
  grid-column-start: 10;
  grid-row-start: 2;
  grid-row-end: 6;
}
.is-full-width-mobile {
  @include mobile {
    width: 100%;
  }
}
.is-hidden-mobile {
  @include mobile {
    display: none;
  }
}

.breadcrumb {
  @include mobile {
    margin-top: 0.5rem;
  }
}

.quota-configuration-boxes {
  @include tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.25rem;
    align-items: flex-start;
  }

  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.active-quota-configuration-box {
  border: 1px solid $primary-alt;
}

// Logout banner to remind users to logout for Dimensions.
#logout-banner.message {
  margin-bottom: 0;
  .message-body {
    border-width: 1px 0 1px 0;
    border-radius: 0;
    padding: 0.75rem 1.5rem;
    font-weight: bold;
  }
}

.weekly-range-grid {
  @extend .quota-grid;
  grid-gap: 1em;
  grid-template-columns: repeat(8, 75px);
}
.weekly-range-grid-advanced {
  grid-template-columns: 100px repeat(8, 75px);
}
.weekly-range-grid-weekly-quota {
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column-start: 8;
}

.weekly-range-grid-weekly-quota-advanced {
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column-start: 9;
}

.weekly-quota-advanced-quota-title {
  font-weight: 600;
  color: $primary-alt;
  text-align: right;
  margin-right: 0.5em;
  align-self: center;
}

.has-text-primary-alt {
  color: $primary-alt;
}

.is-borderless {
  border: none !important;
}

.disc-list-style {
  padding-left: 1em;
}
.disc-list-style > li {
  list-style-type: disc;
}

.unsupported-browser-image {
  @extend .image;
  width: 525px;
  flex-shrink: 0;
}
.unsupported-browser-image > img {
  object-fit: cover;
}

.circle-tag {
  @extend .tag;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border-radius: 66% !important;
}

.horizontal-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  li > label {
    display: flex;
    flex-direction: row;
    padding-right: 0.75rem;
  }
  li > label > input {
    margin-right: 0.25rem;
  }
}

.org-node-picker-aria-modal {
  height: 600px;

  @include mobile {
    // We only want height: 100% on mobile or the vertical centering doesn't work.
    max-height: 100%;
    width: 96%;
    max-width: 96%;
  }
  @include tablet {
    max-height: 80vh !important;
  }
}

.org-node-picker-modal {
  @include mobile {
    width: 100%;
    .org-node-picker-modal-picker-part {
      flex: 1;
    }
  }
  @include tablet {
    width: 768px;
  }
  @include desktop {
    width: 900px;
  }
}

.org-node-button:focus-visible {
  outline: 2px solid #064a61aa;
  outline-offset: -3px;
}

.org-node-picker-modal {
  background: #f4f4f4;
  // 6px matches the border radius of 'box', which we're inside of
  border-radius: 6px;

  .org-node-picker-modal-title {
    margin: 1.5em 1em 1em 1em;
  }

  .org-node-picker-modal-button-container {
    background: #ffffff !important;
    padding: 0 1em 1em 1em;
    // 6px matches the border radius of 'box', which we're inside of
    border-radius: 0 0 6px 6px;
  }

  .org-node-picker-root {
    .org-node-picker-search-container {
      background: #f4f4f4;
      margin: 0 1em;
    }

    .org-node-picker-breadcrumbs-container {
      background: #e2e2e5 !important;
      padding: 0 0.5em;
    }

    .org-node-picker-panes-container {
      background: #ffffff !important;

      .org-node-picker-pane-item:first-child {
        padding-top: 0.6em !important;
      }
      .org-node-picker-pane-item:last-child {
        padding-bottom: 0.6em !important;
      }
    }
  }
}

.org-node-picker-selected-nodes-list {
  max-height: 400px;
  width: 100%;
  overflow-y: auto;
  overflow-x: clip;
  padding-right: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.25em !important;
}

table.nocalltime-table {
  tr {
    td {
      text-align: center;
      div.display-time {
        margin: 0 auto;
        position: relative;
        text-align: center;
      }
      div.add-time {
        input {
          width: inherit;
        }
        select {
          margin-left: 10px;
          width: inherit;
        }
      }
    }
  }
  tr.no-border {
    td {
      border-bottom: 0;
      border-top: 0;
    }
  }
}

.button-with-margin {
  margin: 0 5px;
}

.padded-button {
  padding: 3px 7px;
}

// Account new password widget.

div.set-passwords {
  table {
    input[type="password"] {
      margin: 0;
    }
  }
}

.text-muted {
  color: #777;
  margin: 0;
}

.label-danger {
  background-color: #d9534f;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-info {
  background-color: #5bc0de;
}

.password_info_label {
  font-size: 0.9em;
  margin: 0.25em 0 0 0;
}
